import React from "react";
import { Typography, makeStyles, Button } from "@material-ui/core";
import publicVideoSrc from "../../media/videos/video.mp4";
import stellantisVideoSrc from "../../media/videos/stellantis.mp4";
import Video from "../Video";
import Link from "../Link";
import { lighten } from "@material-ui/core/styles";
import HeroBanner from "./HeroBanner";

const useStyles = makeStyles((theme) => ({
  heroContainer: {
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    padding: "0 1rem",
    position: "relative",
    color: theme.palette.primary.light,
  },
  btn: {
    color: theme.palette.primary.light,
    background: theme.palette.primary.main,
    transition: "0.5s",
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
  heroBg: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  videoBg: {
    width: "100%",
    height: "100%",
    filter: "brightness(0.7)",
  },
  heroContent: {
    zIndex: 3,
    height: "calc(100vh - 300px)",
    maxHeight: "100%",
    padding: "0rem calc((100vw - 1300px) /2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (orientation: landscape)": {
      paddingTop: "7rem",
      paddingBottom: "3rem",
    },
  },
  heroItems: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    maxHeight: "100%",
    padding: "0",
    color: "#fff",
    lineHeight: "1.1",
    fontWeight: "bold",
  },
  h1: {
    fontSize: "clamp(2.2rem, 6vw, 4rem)",
    marginBottom: "1.5rem",
    marginTop: "3rem",
    letterSpacing: "3px",
    padding: "0 1rem",
  },
  h5: {
    fontSize: "clamp(1rem, 3vw, 2rem)",
    marginBottom: "2rem",
  },
}));

const getSrc = () => {
  switch (process.env.GATSBY_BRANDING) {
    case "stellantis":
      return stellantisVideoSrc;
    case "public":
      return publicVideoSrc;
    default:
      return undefined;
  }
};

const HeroTest = () => {
  const classes = useStyles();
  const src = getSrc();
  return (
    <div className={classes.heroContainer}>
      <div className={classes.heroBg}>
        <HeroBanner />
        <Video className={classes.videoBg} src={src} type="video/mp4" />
      </div>
      <div className={classes.heroContent}>
        <div className={classes.heroItems}>
          <Typography variant="h1" gutterBottom className={classes.h1}>
            Zusatzerlöse mit Ihrem Elektrofahrzeug
          </Typography>
          <Typography variant="h5" gutterBottom className={classes.h5}>
            Erzielen Sie zusätzliche Erlöse mit Ihrem Elektrofahrzeug mithilfe
            von THG-Quoten
          </Typography>
          <Button
            variant="contained"
            size="large"
            href="/register"
            component={Link}
            className={classes.btn}
          >
            Jetzt Registrieren
          </Button>
          <div id="vorteile"></div>
          <div className=" mt-[4rem] py-4 border px-4 rounded">
            <a
              href="https://de.trustpilot.com/review/thgquoten.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-center items-center"
            >
              Sehen Sie unsere Bewertungen auf
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5 text-green-400 mx-2"
              >
                <path
                  fillRule="evenodd"
                  d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                  clipRule="evenodd"
                />
              </svg>
              Trustpilot
            </a>
            {/* <!-- End TrustBox widget --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroTest;
