import React from "react";
import {
  Typography,
  makeStyles,
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
} from "@material-ui/core";
import Link from "../Link";
import { lighten } from "@material-ui/core/styles";
import { BusIcon } from "./icons/BusIcon";
import { N1Icon } from "./icons/N1Icon";
import { M1Icon } from "./icons/M1Icon";
import { N23Icon } from "./icons/N23Icon";
import { FleetIcon } from "./icons/FleetIcon";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2rem",
    minHeight: "50vh",
  },
  cards: {
    height: "350px",
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
    display: "grid",
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.8),
      "& $icon": {
        fill: theme.palette.primary.main,
      },
      "& $btn": {
        background: lighten(theme.palette.primary.main, 0.2),
      },
    },
  },
  card: {
    background: "transparent",
  },
  btn: {
    color: theme.palette.primary.light,
    background: theme.palette.primary.main,
    transition: "0.5s",
  },
  icon: {
    fontSize: "5rem",
    transition: "0.5s",
    fill: "black",
  },
  svgIcons: {
    width: "20px",
    height: "20px",
  },
  link: {
    textDecoration: "none",
    textAlign: "center",
  },
  title: {
    fontSize: "clamp(2rem, 6vw, 4rem)",
  },
  div: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  action: {
    justifyContent: "center",
    alignItems: "end",
  },
}));

const Dienstleistungen = () => {
  const prices = useSelector((state) => state.prices.items);
  const classes = useStyles();

  const cardTitles = (type) => {
    switch (type) {
      case "m1":
        return "E-Auto (M1)";
      case "n1":
        return "leichtes E-Nutzfahrzeug (N1)";
      case "n2":
        return "schweres E-Nutzfahrzeug (N2)";
      case "n3":
        return "E-Nutzfahrzeug ab 12 Tonnen (N3)";
      case "m3":
        return "E-Bus (M3)";
      case "fleet":
        return "Flottenbetreiber";
    }
  };
  const cardIcons = (type) => {
    switch (type) {
      case "m1":
        return <M1Icon className={classes.icon} />;
      case "n1":
        return <N1Icon className={classes.icon} />;
      case "n2":
      case "n3":
        return <N23Icon className={classes.icon} />;
      case "m3":
        return <BusIcon className={classes.icon} />;
      case "fleet":
        return <FleetIcon className={classes.icon} />;
    }
  };

  return (
    <Container className={classes.root}>
      <Typography
        variant="h2"
        gutterBottom
        align="center"
        className={classes.title}
      >
        Unsere Preise {new Date().getFullYear()}
      </Typography>
      <Grid container spacing={2}>
        {(prices || [])?.map((p) => {
          return (
            <Grid item xs={12} md={4} key={p.type}>
              <Link href="/register" className={classes.link}>
                <Card className={classes.cards} elevation={0}>
                  <Card
                    align="center"
                    elevation={0}
                    square
                    className={classes.card}
                  >
                    <CardHeader
                      title={cardTitles(p.type)}
                      titleTypographyProps={{ variant: "h6" }}
                    />

                    <CardContent className={classes.content}>
                      {cardIcons(p.type)}

                      <Typography
                        variant="h6"
                        color="textPrimary"
                        align="center"
                        component="p"
                      >
                        <b>{p.price} €</b> pro Fahrzeug und Jahr <br />
                      </Typography>
                    </CardContent>
                  </Card>
                  <CardActions className={classes.action}>
                    <Button
                      variant="contained"
                      size="large"
                      component={Link}
                      className={classes.btn}
                      href={p.p_desc === "fleet" ? "/contact" : "/register"}
                    >
                      {p.p_desc === "fleet"
                        ? "Zum Kontaktformular"
                        : "Jetzt Registrieren"}
                    </Button>
                  </CardActions>
                </Card>
              </Link>
            </Grid>
          );
        })}
      </Grid>
      <div id="ablauf"></div>
    </Container>
  );
};

export default Dienstleistungen;

// const cards = [
//   {
//     title: "E-Auto (M1)",
//     icon: <DirectionsBusIcon />,
//     text: "310 € pro Fahrzeug und Jahr",
//     href: "/register",
//   },
//   {
//     title: "Leichte E-Nutzfahrzeuge (N1)",
//     icon: <DirectionsBusIcon />,
//     text: "500 € pro Fahrzeug und Jahr",
//     href: "/register",
//   },
//   {
//     title: "E-Busse",
//     icon: <AirportShuttleIcon />,
//     text: "13000 € pro Fahrzeug und Jahr",
//     href: "/register",
//   },
//   {
//     title: "Flottenbetreiber",
//     icon: <DirectionsBusIcon />,
//     text: "Registrieren Sie 10 Fahrzeuge und erhalten Sie von uns ein Angebot. Sichern Sie sich <b>310€</b> pro Fahrzeug und Jahr.",
//     href: "/register",
//   },
// ];
