import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  typo: {},
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "32px auto",
    maxWidth: "72rem",
  },
  flex: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "@media (min-width: 1280px)": {
      flexDirection: "row",
    },
    alignItems: "center",
    justifyContent: "space-between",
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    marginTop: 16,
    marginBottom: 16,
  },
}));

const KnownFrom = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h4" color="primary" className={classes.typo}>
        BEKANNT AUS
      </Typography>
      <Grid container align="center" justifyContent="space-between">
        <Grid
          item
          sm={12}
          xs={12}
          md={6}
          lg={1}
          className={classes.gridItem}
          id="register"
        >
          <a
            href="https://www.lifeverde.de/nachhaltigkeitsmagazin/news-tipps/mit-thg-quoten-geld-verdienenthg-quoten-service-von-m3e"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              className={classes.img}
              height={64}
              style={{ minWidth: 205 }}
              src="../../media/images/lifeverde.png"
            />
          </a>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={6}
          lg={1}
          className={classes.gridItem}
          id="register"
        >
          <a
            href="https://www.electrive.net/2021/09/27/m3e-bietet-thg-quotenhandel-fuer-e-fahrzeughalter/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              className={classes.img}
              height={64}
              style={{ minWidth: 272 }}
              src="../../media/images/electrive.png"
            />
          </a>
        </Grid>

        <Grid
          item
          sm={12}
          xs={12}
          md={6}
          lg={1}
          className={classes.gridItem}
          id="register"
        >
          <a
            href="https://www.solarserver.de/2021/09/27/thg-quoten-service-von-m3e-ermoeglicht-erloese-fuer-e-autos/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              className={classes.img}
              height={128}
              style={{ minWidth: 300 }}
              src="../../media/images/solarserver.svg"
            />
          </a>
        </Grid>

        <Grid
          item
          sm={12}
          xs={12}
          md={6}
          lg={1}
          className={classes.gridItem}
          id="register"
        >
          <a
            href="https://www.prosieben.de/serien/galileo/videos/durch-e-autos-geld-verdienen-statt-verlieren-so-gehts"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              className={classes.img}
              height={128}
              style={{ minWidth: 128 }}
              src="../../media/images/galileo.png"
            />
          </a>
        </Grid>
      </Grid>
    </div>
  );
};

export default KnownFrom;
