import React from "react";

const HeroBanner = () => {
  return (
    <div className="bg-orange-400 px-8 py-2.5 sm:px-3.5">
      <div className="mt-24 mb-4 flex items-center justify-center">
        <p className="text-base text-white leading-6 text-center">
          Hinweis: Leider können wir keine Fahrzeuge mehr für 2024 annehmen. Wir
          arbeiten aber daran, dass Sie in Kürze Ihre Fahrzeuge schon wieder für
          2025 anmelden können.
        </p>
      </div>
    </div>
  );
};

export default HeroBanner;
