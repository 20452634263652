import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import SchemaOrg from "./SchemaOrg";
import ReactGA from "react-ga";

const TRACKING_ID = "G-GDL5CFZ0G4";
ReactGA.initialize(TRACKING_ID);

const SEO = ({
  title,
  description,
  image,
  article,
  isBlogPost,
  datePublished,
}) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    organization,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    organization: organization,
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang: "de" }} title={seo.title}>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=$G-GDL5CFZ0G4`}
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "G-GDL5CFZ0G4");
        `}
        </script>

        {/* TrustBox script */}
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
        {/* End TrustBox script */}

        <meta name="robots" content="index, follow" />

        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />

        {seo.url && <meta property="og:url" content={seo.url} />}

        {(article ? true : null) && (
          <meta property="og:type" content="article" />
        )}

        {seo.title && <meta property="og:title" content={seo.title} />}

        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}

        {seo.image && <meta property="og:image" content={seo.image} />}

        <meta name="twitter:card" content="summary_large_image" />

        {seo.title && <meta name="twitter:title" content={seo.title} />}

        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}

        {seo.image && <meta name="twitter:image" content={seo.image} />}
      </Helmet>
      <SchemaOrg
        isBlogPost={isBlogPost}
        url={seo.url}
        title={seo.title}
        image={seo.image}
        description={seo.description}
        datePublished={datePublished}
        siteUrl={seo.siteUrl}
        author={seo.author}
        organization={seo.organization}
        defaultTitle={seo.title}
      />
    </>
  );
};

export default SEO;

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        organization {
          name
          url
          logo
        }
      }
    }
  }
`;
