import React from "react";
import {
  Typography,
  makeStyles,
  Container,
  Grid,
  Button,
  lighten,
} from "@material-ui/core";
import Donate from "../../media/images/donate.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "50vh",
    display: " flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${Donate})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "2rem",
  },
  gridPadding: {
    padding: "2rem",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
  },
  text: {
    // fontSize: "clamp(1rem, 3vw, 1.5rem)",
    textAlign: "left",
    color: theme.palette.primary.light,
  },
  title: {
    color: theme.palette.primary.light,
  },
  icon: {
    fontSize: "180px",
    color: theme.palette.primary.light,
  },
  btn: {
    marginTop: "1rem",
    color: theme.palette.primary.light,
    background: theme.palette.primary.main,
    transition: "0.5s",
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
}));

const Hinweis = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid container className={classes.gridPadding}>
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="h4"
              gutterBottom
              className={classes.title}
            >
              Antragsservice der M3E GmbH
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.text}>
              Für Ihr E-Fahrzeug gibt es zudem eine Vielzahl an
              Fördermöglichkeiten sowohl auf Bundes-, Länder-, aber auch auf
              Kommunalebene. Der Fördermittelservice der M3E GmbH übernimmt den
              kompletten Antragsprozess für Sie und sorgt so dafür, dass Sie von
              allen relevanten Förderprogrammen profitieren!
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              variant="contained"
              size="large"
              className={classes.btn}
              href="https://m3e-gmbh.com/services/foerdermittel"
              external
            >
              Erfahren Sie mehr
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Hinweis;
