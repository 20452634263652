import React from "react";
import { Typography, makeStyles, Container, Grid } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    borderBottomRightRadius: "200px",
    color: theme.palette.primary.light,
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },

  iconGrid: {
    margin: "2rem 0rem 2rem 0rem",
  },
  icons: {
    fontSize: "1.5rem",
    marginRight: ".3rem",
  },
  content: {
    display: "flex",
    marginBottom: "1rem",
    fontSize: "clamp(0.8rem, 1.5vw, 1.2rem)",
  },
  subtitle: {
    fontSize: "clamp(0.8rem, 1.5vw, 1.3rem)",
  },
  title: {
    fontSize: "clamp(2rem, 6vw, 4rem)",
  },
}));

const Vorteile = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container>
        <Typography
          variant="h2"
          gutterBottom
          align="center"
          className={classes.title}
        >
          THG-Quotenservice
        </Typography>
        <Typography
          variant="h6"
          gutterBottom
          align="center"
          className={classes.subtitle}
        >
          Der THG-Quotenservice der M3E GmbH ermöglicht Ihnen ganz einfach
          Erlöse mit Ihrem E-Fahrzeug zu erhalten. Profitieren Sie von unserer
          jahrelangen Erfahrung und Expertise.
        </Typography>
        <Container maxWidth="md">
          <Grid container className={classes.iconGrid}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6" className={classes.content}>
                <CheckCircleOutlineIcon className={classes.icons} />
                Sie können mit Ihrem Elektrofahrzeug jährlich Zusatzerlöse
                erzielen
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6" className={classes.content}>
                <CheckCircleOutlineIcon className={classes.icons} />
                Sie haben keinen Mehraufwand
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6" className={classes.content}>
                <CheckCircleOutlineIcon className={classes.icons} />
                Sie haben einen kompetenten Ansprechpartner - Wir übernehmen den
                kompletten Prozess für Sie
              </Typography>
            </Grid>
            <div id="dienstleistungen"></div>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6" className={classes.content}>
                <CheckCircleOutlineIcon className={classes.icons} />
                Wir kümmern uns um die Registrierung bei den Behörden, die
                Bündelung der Quoten zu einem marktfähigen Paket und um den
                Verkauf der Quoten
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6" className={classes.content}>
                <CheckCircleOutlineIcon className={classes.icons} />
                Wir lassen von unserer Bearbeitungsgebühr für Sie drei Bäume in
                Äthiopien und auf den Philippinen
                pflanzen&nbsp;&nbsp;-&nbsp;Ihnen wird dafür nichts zusätzlich
                berechnet.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </div>
  );
};

export default Vorteile;
